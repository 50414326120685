

import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class AvukatTalepPicker extends Mixins(SimpleInputMixin) {
  @Prop() dosyaId!: number;
  @Prop({required: true}) talepTuru!: 'haciz' | 'tebligat' | 'genel';
  @Prop() multiple!: boolean | string;
  @Prop() returnObject!: boolean | string;

  items: Array<AvukatTalepListCevapRow> = [];
  mdb = new MDB();

  get isMultiple() {
    return this.multiple || this.multiple === "";
  }

  get isReturnObject() {
    return this.returnObject || this.returnObject === "";
  }

  created() {
    this.load();
  }

  tumunuSec() {
    this.$nextTick(() => {
      if (this.localValue.length == this.items.length) {
        this.localValue = []
      } else {
        this.localValue = this.items;
      }
      this.input();
    })
  }

  get icon() {
    if (this.localValue.length == this.items.length)
      return 'mdi-close-box';
    if (this.localValue.length > 0)
      return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  async load() {
    if (this.dosyaId) {
      this.items = (await this.$uyap.AvukatTalepList().run({
        dosyaId: this.dosyaId,
        islemTuru: this.talepTuru
      })).items;
    } else {
      if (this.talepTuru === "haciz") {
        this.items = this.mdb.hacizEvrakTipleri;
      }
      else if (this.talepTuru === "tebligat") {
        this.items = this.mdb.tebligat.talepListesi;
      }
      else if (this.talepTuru === "genel") {
        this.items = this.mdb.digerTalepler;
      }
    }
  }
}
