
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class NewFormDialog extends Vue {
  @Prop({default: "600"}) width!: string | number;
  @Prop() title!: string;
  @Prop() tooltip!: string | HTMLElement;
  @Prop({ default: null }) count!: number | null;
  @Prop({ default: false }) useGreenColor!: boolean;
  @Prop({ default: '' }) defaultColor!: string;

  show: boolean = false;

  get buttonColor() {
    if (this.useGreenColor && this.count && this.count > 0) {
      return 'green darken-1';
    }
    return this.defaultColor || this.$attrs.color || '#040844';
  }

  get displayLabel() {
    const baseLabel = this.$attrs.label || '';
    return this.count !== null ? `${baseLabel} (${this.count})` : baseLabel;
  }

  get displayTooltip() {
    const baseTooltip = this.tooltip || '';
    return this.count !== null ? `${baseTooltip} (${this.count})` : baseTooltip;
  }

  get displayTitle() {
    const baseTitle = this.title || '';
    return this.count !== null ? `${baseTitle} (${this.count})` : baseTitle;
  }

  onClose() {
    this.show = false;
  }

  onSuccess(data: any) {
    this.onClose();
    this.$emit("success", data);
  }

  @Watch('count')
  onCountChange() {
    this.$forceUpdate();
  }
}
